@import '../../styles/helpers/index.scss';

.cta {
  margin: 50px 0 0;
  padding: 110px 0;

  background-image: url('../../static/images/bg/cta.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  color: #fff;

  &__links {
    margin: 60px 0 0;
    @include align-center;

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
      flex-direction: column;
      align-items: flex-start;
    }

    span {
      margin: 0 0 0 25px;
      font-weight: 700;
      font-size: 24px;
      line-height: 200%;

      @media screen and (max-width: $tablet-sm) {
        margin: 10px 0 0;
      }

      a {
        color: #fff;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}
