@import '../../styles/helpers/index.scss';

.why-we {
  position: relative;
  padding: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    padding: 0;
    margin: 70px 0 0;
  }

  &::before {
    content: '';

    position: absolute;
    right: 40px;
    top: -35px;

    background-image: url('../../static/images/pseudo/square.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    width: 230px;
    height: 230px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  .section-title {
    &::before {
      background-image: url('../../static/images/pseudo/section-title_why-we.svg');
      left: -25px;
      bottom: -10px;
      height: 20px;
      width: 160px;

      @media screen and (max-width: $tablet-sm) {
        bottom: -20px;
        left: 0;
      }
    }
  }

  &__grid {
    margin: 70px 0 0;
    @include grid($column-gap: 90px, $columns: repeat(3, 1fr));

    @media screen and (max-width: $tablet-sm) {
      margin: 50px 0 0;
    }

    &--item {
      color: map-get($colors, 'text');

      &__title {
        @include align-center;

        h4 {
          margin: 0 0 0 20px;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
        }
      }

      p {
        margin: 40px 0 0;
        font-size: 18px;
        line-height: 150%;

        @media screen and (max-width: $tablet-sm) {
          margin: 30px 0 0;
        }
      }
    }
  }
}
