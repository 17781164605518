@import '../../styles/helpers/index.scss';

.thanks-wrapper {
  padding: 140px 0;
  text-align: center;

  .subtitle {
    margin: 10px 0 15px;
    font-size: 24px;
    font-weight: 700;
  }

  p {
    font-size: 18px;
  }

  a {
    max-width: 250px;
    margin: 30px auto 0;
  }
}
