@import '../../styles/helpers/index.scss';

.page-footer {
  position: relative;
  color: map-get($colors, 'text');

  background-color: map-get($colors, 'grey');

  font-size: 24px;
  line-height: 36px;

  &::before {
    content: '';

    background-image: url('../../static/images/pseudo/square.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    position: absolute;
    top: -5%;
    right: 30%;

    height: 180px;
    width: 180px;

    user-select: none;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  &__inner {
    @include space-between;
    padding: 125px 0 110px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      padding: 70px 0 50px;
    }

    & > div {
      &:first-of-type {
        @media screen and (max-width: $tablet-sm) {
          text-align: center;
        }

        p {
          margin: 65px 0 20px;
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          text-transform: uppercase;

          @media screen and (max-width: $tablet-sm) {
            margin: 30px 0;
          }
        }
      }
    }
  }

  &__social {
    & > a {
      @include scale;
      display: inline-block;
      width: 42px;
      height: 42px;
      background-color: map-get($colors, 'red');
      border-radius: 50%;

      text-align: center;
      line-height: 42px;

      &:not(:last-of-type) {
        margin: 0 20px 0 0;
      }

      i {
        color: map-get($colors, 'grey');
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
      align-items: center;
    }
  }

  &__logo {
    display: block;
    @include scale;
    height: 50px;

    img {
      height: inherit;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 auto;
      }
    }
  }

  a {
    color: map-get($colors, 'text');
    font-weight: 400;

    &[href^='tel:'] {
      font-size: 40px;
      line-height: 150%;
      margin: 0 0 35px;
    }

    &[href^='mailto:'] {
      font-size: 24px;
      line-height: 150%;
    }
  }

  &__underline {
    background-color: #fff;

    @media screen and (max-width: $tablet-sm) {
      padding: 0 0 60px;
    }
    .container {
      border-top: 1px solid #fff;
      padding-top: 20px;
      padding-bottom: 20px;

      font-size: 12px;
      line-height: 180%;

      user-select: none;

      & > div {
        width: 100%;
        @include space-between;
        margin: 0 0 30px;

        @media screen and (max-width: $tablet-sm) {
          flex-direction: column;
          text-align: center;
        }
      }
    }

    a {
      margin-top: 10px;
      color: map-get($colors, 'text');

      &[rel='noReferrer'] {
        display: block;
        text-align: center;
      }

      strong {
        font-weight: bold;
      }
    }

    &--links {
      @media screen and (max-width: $tablet-sm) {
        margin: 15px 0 0;
      }
      a {
        color: map-get($colors, 'text');
        &:not(:last-of-type) {
          margin: 0 10px 0 0;
        }

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}
