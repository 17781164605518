@import '../../styles/helpers/index.scss';

.get-in-touch-form-wrapper {
  form {
    @include grid(0, 40px, 1fr, repeat(2, 1fr));

    input,
    textarea,
    select {
      outline: none;
      border: none;
      font-family: 'Montserrat', sans-serif;
      &:not([type='submit']) {
        width: 100%;
        margin: 0 0 20px;
        padding: 10px 25px;
        background: #fff;
        border: none;
        color: map-get($colors, 'text');
        font-weight: 300;
        font-size: 16px;
        line-height: 155%;
        appearance: none;
        -webkit-appearance: none;
        &::placeholder {
          font-family: inherit;
          font-weight: inherit;
          font-size: inherit;
          line-height: inherit;
          color: inherit;
        }
      }
    }

    input[type='number'] {
      appearance: none;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    input,
    select {
      height: 50px;
      &[type='submit'] {
        cursor: pointer;
        margin: 20px 0 0;
      }
    }

    textarea {
      height: 190px;
    }

    .red-button {
      max-width: 150px;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }
    }
  }
}
